import React, { Component } from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarTe from "../../components/layout/sidemenu/sidemenu_te"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import unit1Icon from "../../images/teacher-resources/unit_01_icon.svg"
import unit2Icon from "../../images/teacher-resources/unit_02_icon.svg"
import unit3Icon from "../../images/teacher-resources/unit_03_icon.svg"

import Auth from '../../auth/auth'
import SignInOutButton from "../../components/auth/sign-in-out-button"

let auth
if (typeof window !== `undefined`) {
  auth = new Auth()
}

const StudentMaterialsPage = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: undefined
    }
  }

  componentDidMount() {
    const user = auth.getUser()
    if (user) {
      this.setState({user_email: user.email, authenticated: auth.isAuthenticated()})
    } else {
      this.setState({ authenticated: auth.isAuthenticated() })
    }
  }

  signin = (e) => {
    e.preventDefault()
    auth.signin()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  signout = (e) => {
    e.preventDefault()
    auth.signout()

    this.setState({authenticated: auth.isAuthenticated()})
  }

  render() {
    return (
      <Layout location={"/teacher-resources/"} >
        <SEO title="Carbon Connections - Student Materials" keywords={[`gatsby`, `application`, `react`]} />
        <Container fluid className="mb-5">
          <Row>
            <Col md="auto">
              <SideBarTe location={this.props.location.pathname} />
            </Col>
            <Col>
              <CCBreadcrumb
                pathname={this.props.location.pathname}
                title={'Student Materials'}
                replace={this.props.replace}
              />
              <Alert
                className="w-100"
                variant="warning"
              >
                BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
              </Alert>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header className="cardHeader">
                          <h1>
                            <span className="headerFontBlue">Student Materials</span>
                          </h1>
                        </Card.Header>
                        {auth && !auth.isAuthenticated() &&
                          <Card.Body className="cardBodyBackground">
                            <div className="d-flex justify-content-center">
                              <SignInOutButton
                                signin={this.signin}
                                signout={this.signout}
                                authenticated={this.state.authenticated}
                              />
                            </div>
                          </Card.Body>
                        }
                        {auth && auth.isAuthenticated() &&
                          <Card.Body className="cardBodyBackground">
                            <Row className="mb-4">
                              <Col>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <div className="d-flex">
                                      <div className="mr-3">
                                        <img
                                          className="img-fluid unitIcon"
                                          src={ unit1Icon }
                                          alt="Unit 1 icon"
                                        />
                                      </div>
                                      <div>
                                        <h2 style={{ lineHeight: '1' }}>Unit 1: <span className="headerFontBlue">Carbon &amp; Climate in the Past</span></h2>
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_1.0_carbonconnections_student_unit_1_overview.pdf">
                                            Lesson 1.0: Overview&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_1.1_carbonconnections_student.pdf">
                                            Lesson 1.1: Carbon Fizz&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_1.2_carbonconnections_student.pdf">
                                            Lesson 1.2: Carbon from the Past&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_1.3_carbonconnections_student.pdf">
                                            Lesson 1.3: Carbon Forcing&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_1.4_carbonconnections_student.pdf">
                                            Lesson 1.4: Global Connections&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_1.5_carbonconnections_student.pdf">
                                            Lesson 1.5: Core Connections&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                    </ul>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Col>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <div className="d-flex">
                                      <div className="d-block mr-3">
                                        <img
                                          className="img-fluid unitIcon"
                                          src={ unit2Icon }
                                          alt="Unit 2 icon"
                                        />
                                      </div>
                                      <div>
                                        <h2 style={{ lineHeight: '1' }}>Unit 2: <span className="headerFontBlue">Carbon Now</span></h2>
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_2.0_carbonconnections_student_unit_2_overview.pdf">
                                            Lesson 2.0 Overview&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_2.1_carbonconnections_student_0.pdf">
                                            Lesson 2.1 Moving Carbon&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_2.2_carbonconnections_student_0.pdf">
                                            Lesson 2.2: Exploring Carbon&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_2.3_carbonconnections_student_0.pdf">
                                            Lesson 2.3: The Breathing Biosphere&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_2.4_carbonconnections_student_0.pdf">
                                            Lesson 2.4: Carbon Cycling&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_2.5_carbonconnections_student_0.pdf">
                                            Lesson 2.5: Earth Takes a Breath&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                    </ul>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>

                            <Row className="mb-4">
                              <Col>
                                <Card className="h-100">
                                  <Card.Header style={{ backgroundColor: '#f3f3f3' }}>
                                    <div className="d-flex">
                                      <div className="mr-3">
                                        <img
                                          className="img-fluid unitIcon"
                                          src={ unit3Icon }
                                          alt="Unit 3 icon"
                                        />
                                      </div>
                                      <div>
                                        <h2 style={{ lineHeight: '1' }}>Unit 3 :<span className="headerFontBlue">Carbon in the Future &amp; You</span></h2>
                                      </div>
                                    </div>
                                  </Card.Header>
                                  <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                                    <ul className="list-unstyled">
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_3.0_carbonconnections_student_unit_3_overview.pdf">
                                            Lesson 3.0: Overview&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_3.1_carbonconnections_student_0.pdf">
                                            Lesson 3.1: Your Temperature Connections&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_3.2_carbonconnections_student_0.pdf">
                                            Lesson 3.2: Testing Forcings&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_3.3_carbonconnections_student_0.pdf">
                                            Lesson 3.3 Future Forcings&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_3.4_carbonconnections_student_0.pdf">
                                            Lesson 3.4: It Starts at Home&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                      <li className="mb-3">
                                        <a
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          href="http://media.bscs.org/carbonconnections/teacher_pages/pdf/student_materials/l_3.5_carbonconnections_student_0.pdf">
                                            Lesson 3.5: Carbon Claims&nbsp;<sup><i className="fas fa-external-link-alt"></i></sup>
                                          </a>
                                      </li>
                                    </ul>
                                  </Card.Body>
                                </Card>
                              </Col>
                            </Row>

                          </Card.Body>
                        }
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default StudentMaterialsPage
